import { FormEventHandler } from 'react';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { Button } from 'components/UI/Button/Button';
import { FieldError, UseFormRegister } from 'react-hook-form';
import {
  fieldRequiredMessage,
  incorrectDeviceIdMessage,
  incorrectGatewayCommandMessage,
} from 'assets/messages/errorMessages';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';

export interface FormInput {
  deviceId: string;
  command: string;
}
export interface FormErrors {
  deviceId?: FieldError;
  command?: FieldError;
}

interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const GatewaysSendCommandForm = ({
  onSubmit,
  register,
  errors,
  placeholder = '',
}: Props) => {
  return (
    <form aria-label="form" onSubmit={onSubmit}>
      <StyledTextLabel htmlFor="deviceId">Device Id:</StyledTextLabel>
      <StyledTextInput
        {...register('deviceId', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          setValueAs: autofillG4ComboardId(),
          pattern: {
            value: /^\d{9}-\d{9}$/,
            message: incorrectDeviceIdMessage,
          },
        })}
        placeholder={placeholder}
      />
      {errors.deviceId && <StyledFormError>{errors.deviceId.message}</StyledFormError>}
      <StyledTextLabel>Command:</StyledTextLabel>
      <StyledTextInput
        {...register('command', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          pattern: {
            value: /[A-Za-z]+\.[A-Za-z]+\([^)]*\)$/,
            message: incorrectGatewayCommandMessage,
          },
        })}
        placeholder="Family.Command(attribute:value)"
      />
      {errors.command && <StyledFormError>{errors.command.message}</StyledFormError>}
      <Button type="submit">Send</Button>
    </form>
  );
};
