export const fieldRequiredMessage = 'This field is required.';
export const incorrectDeviceIdMessage =
  'Device Id must contain nine numbers, followed by a dash, followed by nine numbers.';
export const incorrectIprIdMessage = 'Invalid Ipr Id.';
export const incorrectEmailMessage = 'Please provide a valid email address.';
export const tooLongMowerNameMessage = 'Mower name must be under 22 characters long.';
export const incorrectMowerNameMessage = 'Your mower name contains a symbol that is not allowed.';
export const incorrectSerialNumberMessage = 'Please supply a nine-digit serial number.';
export const invalidRemoteDebugSessionLength =
  'Please choose a session length between 1 and 365 days.';
export const incorrectGatewayCommandMessage= 'Command should contain this format, family.type(attribute:value)';
