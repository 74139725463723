import { PageHeader } from 'components/fragments/PageHeader/PageHeader';
import 'react-toastify/dist/ReactToastify.css';
import BackendPairingPage from 'pages/BackendPairingPage/BackendPairingPage/BackendPairingPage';
import GdprPage from 'pages/GdprPage/GdprPage';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import { MapFilesPage } from 'pages/MapFilesPage/MapFilesPage';
import MowerStatusPage from 'pages/MowerStatusPage/MowerStatusPage';
import { FetchTwinPage } from 'pages/FetchTwinPage/FetchTwinPage';
import { DeleteTwinPage } from 'pages/DeleteTwinPage/DeleteTwinPage';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { checkSession } from 'store/authState/authState';
import { ErrorDisplay } from 'components/fragments/ErrorDisplay/ErrorDisplay';
import OperationToolInfoPage from 'pages/OperationToolInfoPage/OperationToolInfoPage';
import { RemoteDebuggingPage } from 'pages/RemoteDebuggingPage/RemoteDebuggingPage';
import { MowerGatewayDebuggingPage } from 'pages/MowerGatewayDebuggingPage/MowerGatewayDebuggingPage';
import { MowerEnvironmentPage } from 'pages/MowerEnvironmentPage/MowerEnvironmentPage';
import { GatewaySendCommandPage } from 'pages/GatewaySendCommandPage/GatewaySendCommandPage';
import { StyledApp, StyledContent } from './App.styled';
import ThemeWrapper from './styles/ThemeWrapper';

const RequireAuth = ({
  children,
  authenticated,
}: {
  children: JSX.Element;
  authenticated: boolean;
}) => {
  return authenticated ? children : <Navigate to="/" />;
};

export const App = () => {
  const dispatch = useAppDispatch();
  dispatch(checkSession());
  const {
    session: { authenticated },
  } = useAppSelector((state) => state.auth);
  return (
    <StyledApp>
      <ThemeWrapper>
        <BrowserRouter>
          <PageHeader />
          <StyledContent>
            <Routes>
              <Route index element={<LoginPage />} />
              <Route
                path="/info"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <OperationToolInfoPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/mower-status"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <MowerStatusPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/fetch-twin"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <FetchTwinPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/delete-twin"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <DeleteTwinPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/gdpr-report"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <GdprPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/map-files"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <MapFilesPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/backend-pairing"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <BackendPairingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/remote-debugging"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <RemoteDebuggingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/mower-gateway-debugging"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <MowerGatewayDebuggingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/mower-environment"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <MowerEnvironmentPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/gateway-send-command"
                element={
                  <RequireAuth authenticated={authenticated}>
                    <GatewaySendCommandPage />
                  </RequireAuth>
                }
              />
            </Routes>
          </StyledContent>
          <ErrorDisplay />
        </BrowserRouter>
      </ThemeWrapper>
    </StyledApp>
  );
};
