import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  GatewaySendCommandRequest,
  GDPRinfo,
  isAllowedInLiveResponse,
  MowerEnvironmentRequest,
  MowerGatewayDebuggingRequest,
  SuccessResponse,
  TwinExistsResponse,
} from 'models/apiModels';
import { AmcError, RTKQueryError } from 'models/errors/errorModels';
import { ToolsError } from 'models/errors/ToolsError';
import {
  RemoteDebugSession,
  RemoteDebugSessionRequest,
  MowerEnvironmentSession,
} from 'models/remoteDebugSessionModel';
import { logOut } from 'store/authState/authState';
import { selectCurrentEnv } from 'store/envState/envState';
import { displayError } from 'store/errorState/errorState';
import { RootState } from 'store/Store';
import { mowerLogInWithRetry, remoteDebugSessionStartedWithRetry } from './helpers';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DEV,
  credentials: 'include',
});

const envUrls: Record<string, string> = {
  dev: '',
  qa: process.env.REACT_APP_API_QA ?? '',
  live: process.env.REACT_APP_API_LIVE ?? '',
  mock: 'http://localhost:3000',
};

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  currentStore,
  extraOptions,
) => {
  const currentEnvironment =
    process.env.NODE_ENV === 'development'
      ? 'mock'
      : selectCurrentEnv(currentStore.getState() as RootState);

  const adjustedArgs =
    typeof args === 'string'
      ? envUrls[currentEnvironment] + args
      : { ...args, url: envUrls[currentEnvironment] + args.url };

  let result = await rawBaseQuery(adjustedArgs, currentStore, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await fetch(`${process.env.REACT_APP_LOGIN_API_URL}/v1/refresh`, {
      credentials: 'include',
    });
    if (refreshResult.status === 200) {
      result = await rawBaseQuery(adjustedArgs, currentStore, extraOptions);
    } else {
      currentStore.dispatch(
        displayError(
          new ToolsError(
            'Authentication failure',
            'Re-authentication failed. Please contact amc-support.',
          ),
        ),
      );
      currentStore.dispatch(logOut());
    }
  }
  return result;
};

export const toolsApi = createApi({
  reducerPath: 'toolsApi',
  // Since we know the shape of all errors that can be returned from the Tools API, we cast the BaseQueryFn to include our own custom error type,
  // which builds upon the standard FetchBaseQueryError type.
  baseQuery: dynamicBaseQuery as BaseQueryFn<string | FetchArgs, unknown, RTKQueryError>,
  tagTypes: ['RemoteDebugSessions', 'Twin'],
  endpoints: (builder) => ({
    getMowerStatus: builder.query({
      query: (mowerId: string) => ({
        url: `/tools/v1/mowers/${mowerId}/status`,
      }),
    }),

    fetchTwin: builder.query({
      query: (mowerId: string) => ({
        url: `/tools/v1/mowers/${mowerId}/twin`,
        method: 'GET',
      }),
      providesTags: ['Twin'],
    }),

    getTwinExists: builder.query<TwinExistsResponse, string>({
      query: (mowerId) => ({
        url: `/tools/v1/mowers/${mowerId}/twin/exists`,
        method: `GET`,
      }),
    }),

    deleteTwin: builder.mutation({
      query: (mowerId: string) => ({
        url: `/tools/v1/mowers/${mowerId}/twin`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Twin'],
    }),

    getGdprReport: builder.mutation<GDPRinfo, { email: string }>({
      query: (body) => ({
        url: '/tools/v1/gdpr',
        method: 'POST',
        body,
      }),
    }),
    getMapFile: builder.query({
      query: (optionsObject: { mowerId: string; mapType: string }) => ({
        url: `/tools/v1/mowers/${optionsObject.mowerId}/map?mapType=${optionsObject.mapType}`,
        responseHandler: (res) => (res.status === 200 ? res.text() : res.json()),
      }),
    }),
    backendPairing: builder.mutation({
      query: (pairingObject) => ({
        url: '/tools/v1/mowers/pairing',
        method: 'POST',
        body: pairingObject,
      }),
    }),

    getAllRemoteDebugSessions: builder.query<RemoteDebugSession[], undefined>({
      query: () => ({ url: '/tools/v1/mowers/remoteDebugSession', method: 'GET' }),
      providesTags: ['RemoteDebugSessions'],
    }),
    postRemoteDebugSession: builder.mutation<undefined, RemoteDebugSessionRequest>({
      query: (remoteDebugSession: RemoteDebugSessionRequest) => {
        const serialNumber = Number(remoteDebugSession.serialNumber.split('-')[0]);
        return {
          url: `/tools/v1/mowers/${serialNumber}-999999999/remoteDebugSession`,
          method: 'POST',
          body: {
            requestedBy: remoteDebugSession.requestedBy,
            endingTimestamp: remoteDebugSession.endingTimestamp,
            mowerEnvironmentSession: remoteDebugSession.mowerEnvironmentSession,
          },
          responseHandler: (res) => (res.status === 200 ? res.text() : res.json()),
        };
      },
      invalidatesTags: ['RemoteDebugSessions'],
    }),
    postMowerGatewayDebugRequest: builder.mutation<undefined, MowerGatewayDebuggingRequest>({
      query: (request) => {
        return {
          url: `/tools/v1/mowers/mowerGatewayDebugging`,
          method: 'POST',
          body: { deviceId: request.deviceId },
          responseHandler: (res) => (res.status === 200 ? res.text() : res.json()),
        };
      },
    }),
    postGatewaySendCommandRequest: builder.mutation<undefined, GatewaySendCommandRequest>({
      query: (request) => {
        return {
          url: `/tools/v1/mowers/gatewaySendCommand`,
          method: 'POST',
          body: {
            deviceId: request.deviceId,
            command: request.command,
          },
        };
      },
    }),
    mowerEnvironment: builder.mutation<SuccessResponse, MowerEnvironmentRequest>({
      async queryFn(req, _api, _extraOptions, baseQuery) {
        const serialNumber = Number(req.mowerId.split('-')[0]);
        const minutes = 1000 * 60 * 7;
        const oldEnv: MowerEnvironmentSession = 'OLD_ENV';

        if (req.newEnvironment === 'live') {
          const allowedInLiveResult = await baseQuery(
            `/tools/v1/mowers/${serialNumber}-999999999/allowed-in-live`,
          );
          if (allowedInLiveResult.error) return { error: allowedInLiveResult.error };

          if (
            isAllowedInLiveResponse(allowedInLiveResult.data) &&
            allowedInLiveResult.data.isAllowed === false
          ) {
            const customRtkQueryError: AmcError = {
              status: 400,
              data: {
                title: 'Bad request',
                detail: 'Mowers with keyId 0 (i.e. WinMowers) are not allowed in Live',
                statusCode: 400,
                id: 'custom-id',
              },
            };
            return { error: customRtkQueryError };
          }
        }

        const proxyincloudResult = await baseQuery({
          url: '/tools/v1/mowers/proxyincloud/ownership',
          method: 'POST',
          body: { serialNumber },
        });
        if (proxyincloudResult.error) return { error: proxyincloudResult.error };

        const remoteDebugResult = await baseQuery({
          url: `/tools/v1/mowers/${serialNumber}-999999999/remoteDebugSession`,
          method: 'POST',
          body: {
            requestedBy: req.requestedBy,
            endingTimestamp: Date.now() + minutes,
            mowerEnvironmentSession: oldEnv,
          },
        });
        if (remoteDebugResult.error) return { error: remoteDebugResult.error };

        const remoteDebugSessionStarted = await remoteDebugSessionStartedWithRetry(
          serialNumber,
          baseQuery,
        );

        if (!remoteDebugSessionStarted) {
          const customRtkQueryError: AmcError = {
            status: 400,
            data: {
              title: 'Bad request',
              detail: 'The backend could not reach the mower via AMC Backend.',
              statusCode: 400,
              id: 'custom-id',
            },
          };
          return { error: customRtkQueryError };
        }

        const isMowerLogInCompleted = await mowerLogInWithRetry(serialNumber, baseQuery);

        if (!isMowerLogInCompleted) {
          const customRtkQueryError: AmcError = {
            status: 418,
            data: {
              title: 'Unsucessful login.',
              detail: 'Error when trying to reach mower.',
              statusCode: 418,
              id: 'custom-id',
            },
          };
          return { error: customRtkQueryError };
        }

        const amcBackendHostResult = await baseQuery({
          url: `/tools/v1/mowers/${serialNumber}-999999999/proxyincloud/amc-backend-host`,
          method: 'PUT',
          body: { env: req.newEnvironment },
        });
        if (amcBackendHostResult.error) return { error: amcBackendHostResult.error };

        const amcBackendReConnectResult = await baseQuery({
          url: `/tools/v1/mowers/${serialNumber}-999999999/proxyincloud/amc-backend-reconnect`,
        });
        if (amcBackendReConnectResult.error) return { error: amcBackendReConnectResult.error };

        return { data: { response: 'Request succeeded.' } };
      },
    }),
    mowerEnvironmentCleanUp: builder.mutation<SuccessResponse, MowerEnvironmentRequest>({
      async queryFn(req, _api, _extraOptions, baseQuery) {
        const serialNumber = Number(req.mowerId.split('-')[0]);
        const minutes = 1000 * 60 * 7;
        const newEnv: MowerEnvironmentSession = 'NEW_ENV';

        const remoteDebugNewEnvResult = await baseQuery({
          url: `/tools/v1/mowers/${serialNumber}-999999999/remoteDebugSession`,
          method: 'POST',
          body: {
            requestedBy: req.requestedBy,
            endingTimestamp: Date.now() + minutes,
            mowerEnvironmentSession: newEnv,
          },
        });
        if (remoteDebugNewEnvResult.error) return { error: remoteDebugNewEnvResult.error };

        return { data: { response: 'Request succeeded.' } };
      },
    }),
  }),
});

export const {
  usePostMowerGatewayDebugRequestMutation,
  useBackendPairingMutation,
  useGetGdprReportMutation,
  useGetMapFileQuery,
  useLazyGetTwinExistsQuery,
  useGetMowerStatusQuery,
  useLazyFetchTwinQuery,
  useDeleteTwinMutation,
  useGetAllRemoteDebugSessionsQuery,
  usePostRemoteDebugSessionMutation,
  useMowerEnvironmentMutation,
  useMowerEnvironmentCleanUpMutation,
  usePostGatewaySendCommandRequestMutation,
} = toolsApi;
