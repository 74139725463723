import { useAppSelector } from 'hooks/hooks';
import { useState, MouseEvent } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { usePostGatewaySendCommandRequestMutation } from 'store/api/tools-v1';
import { LiveWarningModal } from 'components/fragments/LiveWarningModal/LiveWarningModal';
import { GatewaySendCommandRequest } from 'models/apiModels';
import { roles } from '../../constants';
import { ToolSidebar } from '../../components/fragments/ToolSidebar/ToolSidebar';
import { FormInput, GatewaysSendCommandForm } from './GatewaySendCommandForm';
import { StyledGatewaySendCommandPresentation } from './styled';

export const GatewaySendCommandPage = () => {
  const { role } = useAppSelector((state) => state.auth.session);
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [liveWarningModalVisible, setLiveWarningModalVisible] = useState(false);
  const paramDeviceId = searchParams.get('mowerId') ?? undefined;
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  const paramGatewayCommand = searchParams.get('gatewayCommand') ?? undefined;
  const [command, setCommand] = useState<string | undefined>(paramGatewayCommand);
  const [waitingRequest, setWaitingRequest] = useState<GatewaySendCommandRequest | undefined>(
    undefined,
  );
  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }
  const [sendCommandRequest, { data, error: queryError, isLoading, isSuccess }] =
    usePostGatewaySendCommandRequestMutation();

  const currentEnvironment = searchParams.get('env') ?? 'qa';

  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, mowerId: formData.deviceId, command: formData.command };
    setSearchParams(params, { replace: true });
    setDeviceId(formData.deviceId);
    setCommand(formData.command);

    const gatewayCommandSendRequest: GatewaySendCommandRequest = {
      deviceId: formData.deviceId,
      command: formData.command,
    };
    setWaitingRequest(gatewayCommandSendRequest);
    if (currentEnvironment === 'live') {
      setWaitingRequest(gatewayCommandSendRequest);
      setLiveWarningModalVisible(true);
    } else {
      sendCommandRequest(gatewayCommandSendRequest);
    }
  };

  const liveWarningModalOnOkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (waitingRequest) {
      sendCommandRequest(waitingRequest);
      setWaitingRequest(undefined);
    }
    setLiveWarningModalVisible(false);
  };

  const liveWarningModalOnCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLiveWarningModalVisible(false);
    setWaitingRequest(undefined);
  };

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      deviceId: deviceId ?? '',
      command: command ?? '',
    },
  });

  return (
    <>
      <ToolSidebar>
        <GatewaysSendCommandForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholder="123456789-123456789"
        />
      </ToolSidebar>
      <StyledPageWrapper>
        <StyledGatewaySendCommandPresentation>
          <h1>Gateway Send Command</h1>
          {liveWarningModalVisible && (
            <LiveWarningModal
              onOkClickHandler={liveWarningModalOnOkClick}
              onCancelClickHandler={liveWarningModalOnCancelClick}
            />
          )}
          {isLoading && <h2>Loading command...</h2>}
          {queryError && !isSuccess && <h2> Failed to execute command</h2>}
          {!queryError && isSuccess && data && (
            <>
              <h3>Command sent</h3>
              <ReactJson src={data} displayObjectSize={false} collapsed={1} name={false} />
            </>
          )}
          <hr />
          <h4> Instructions: </h4>
          <p>
            Enter the Device Id of the mower for which you want send command to in the AMC Gateways.
            The device ID for a G3 mower is the nine-digit serial number of the mower,
          </p>
          <p>
            followed by a dash, followed by the nine-digit comboard ID. For G4 mowers, just input
            the nine-digit serial number of the mower.
          </p>
          <p>
            Enter the command you want to use in the format Family.Command(attribute:value) or
            Family.Command().
          </p>
          <p>
            <b>IMPORTANT: </b> In live environment, in the case of a mower owned by an end customer,
            you need the explicit consent of that customer to exceute this Gateway Command for this
            occasion.
          </p>
        </StyledGatewaySendCommandPresentation>
      </StyledPageWrapper>
    </>
  );
};
